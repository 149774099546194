.container {
  align-items: center;
  width: 100%;
}

.container * {
  min-width: 0;
  min-height: 0;
}

.slickWrapper {
  position: relative;
  margin-left: calc(var(--space6) * 1);
}

.controlDisabled {
  color: var(--lightNeutralColor);
  cursor: default;
}

.buttonContainer {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 35px;
  height: 70px;
  margin-top: calc(-70px / 2);
}


.leftButton {
  left: calc(var(--space10) * -2);
}

.rightButton {
  right: calc(var(--space10) * -2);
}

.buttonBackground {
  width: 35px;
  height: 70px;
  background: var(--lightestNeutralColor);
  opacity: 0.3;
}

.leftButton .buttonBackground {
  border-radius: 0 70px 70px 0;
  box-shadow: var(--leftDeepShadow);
}

.rightButton .buttonBackground {
  border-radius: 70px 0 0 70px;
  box-shadow: var(--rightDeepShadow);
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
}

.button button {
  position: absolute;
  top: calc(50% - 24px);
}

.leftButton button {
  left: -12px;
}

.rightButton button {
  left: 0;
}

.hidden {
  visibility: hidden;
}

.slick-slide {
  padding: 0.75rem;
  width: 216px;
}

.slick-list> div {
  margin-left:0;
}

@media screen and (max-width: 580px) {
  .slickWrapper {
    width: 97%;
  }

  .leftButton {
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(10%);
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .slickWrapper {
    width: 98%;
  }
}
