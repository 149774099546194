.search-bar-root {
  position: relative;
  overflow: hidden;
}

.search-bar {
  width: calc(100% - (var(--space32) + var(--space16)));
  max-width: 300px;
  height: 15px;
  padding: var(--space8) var(--space32) var(--space8) var(--space16);
  font-size: var(--font14);
  border: 1px solid var(--lightestGreyColor);
  border-radius: 0.1875rem;
}

.rtl .search-bar {
  padding: var(--space8) var(--space16) var(--space8) var(--space32);
}

.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  font-size: var(--font18);
  background: transparent;
  border: transparent;
  padding: 4px 0px;
  width: 40px;
}

.rtl .search-icon {
  right: initial;
  left: 0;
}

.search-icon:hover {
  cursor: pointer;
}
