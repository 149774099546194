.card-root {
  position: relative;
  display: inline-block;
  width: 218px;
  white-space: normal;
}

.visually-hidden{
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.rtl .card-root {
direction: rtl;
}

@media screen and (-ms-high-contrast: active) {
  .card-root {
    border: 1px WindowText solid !important;
  }
}

@media screen and (max-width: 720px) {
  .card-content-due-date {
  bottom: var(--space3);
  }
}

@media screen and (min-width: 721px) {
  .card-content-due-date {
    bottom: var(--space16);
  }
}

.card-root > .card-root-body {
  display: block;
  float: left;
  width: 100%;
  height: 260px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: var(--reverseColor);
  box-shadow: var(--mediumShadow);
}

.card-root > .card-root-body:hover {
  box-shadow: var(--deepShadow);
}


.card-root > .card-root-body:focus {
  outline: var(--lightPrimaryColor) solid 2px;
  outline-offset: 2px;
}

.card-info-icon {
  color: #707070;
}

.card-image-back {
  position: relative;
  width: 100%;
  height: 109px;
  background: var(--grayGradient);
}

.card-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.card-past-due {
  position: absolute;
  top: var(--space8);
  left: var(--space8);
  padding: 4px 12px;
  font-size: var(--font10);
  color: var(--reverseColor);
  background-color: var(--chartRedColor);
  border-radius: 24px;
}

.rtl .card-past-due {
  right: var(--space8);
  left: initial;
}

.card-content {
  position: relative;
  height: 260px;
}

.card-content-type {  
  position: absolute;
  top :126px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font14);
  color: var(--lightNeutralColor);
  margin-left :15px;
}

.card-content-title {
  display: -webkit-box;
  overflow: hidden;
  font-size: var(--font14);
  font-weight: bold;
  line-height: 16px;
  color: var(--darkNeutralColor);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-right: 15px;
}

.card-content-title:focus {
  outline: var(--lightPrimaryColor) solid 2px;
}

.card-content-due-date {
  position: absolute;
  font-size: var(--font12);
  color: var(--darkNeutralColor);
  margin-left:15px;
}

.card-overdue {
  color: var(--chartRedColor);
  margin-left:15px;

}

.card-content-subtext {
  margin-top: var(--space8);
  font-size: var(--font10);
  color: #1D8159;
}

.card-content-type > div:first-child {
  align-items: center;
  margin-right: var(--space8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-info {
  position: absolute;
  top: 126px;
  right: var(--space16);
  z-index: 10;
  font-size: var(--font16);
  color: var(--lighterNeutralColor);
  cursor: pointer;
}

.rtl .card-info {
  left: var(--space16);
  right: initial;
}

.card-info:hover {
  color: var(--darkPrimaryColor);
}

.card-info:focus {
  outline: var(--lightPrimaryColor) solid 2px;
  outline-offset: 2px;
}

.card-content-header {
  position: relative;
  margin-bottom: auto;
  top: 25px;
  margin-left: 15px;
}