.toast-root {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
}

.toast-root > div {
  position: fixed;
  top: var(--space56);
  right: var(--space8);
  z-index: var(--zAboveAllPlusOne);
}

.rtl .toast-root > div {
  right: initial;
  left: var(--space8);
}

.toast-action {
  margin-left: 0.5em;
}
