.playerWrap {
    width: calc(100% - 40px);
    max-width: 1200px;
    text-align: center;
    margin: 20px auto;
    position: relative;
}

.playerWrapFullScreen {
    width: 100%;
    text-align: center;
    position: relative;
}

.playerWrap > iframe {
    border: 0px;
    width: 100%;
}

:global(.rtl) .a11yToggle {
    left: 0px;
    right: unset;
}

:global(.rtl) .a11yToggle >label {
    margin-left: var(--space8);
}

.a11yToggle {
    position: absolute;
    top: -44px;
    right: 0px;
    display: flex;
    align-items: center;
}

.a11yToggle > label {
    margin-right: var(--space8);
    color: #707064;
}

.a11yToggle > span {
    margin-right: var(--space8);
    color: var(--lightNeutralColor);
}

.a11yToggle > a > div {
    vertical-align: middle;
}

button[id = 'a11yToggle']:focus{
    outline : var(--lightPrimaryColor) solid 4px;
  }