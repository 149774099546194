.ReactModal__InnerContent {
  position: relative;
  padding-bottom: 90px;
}

.modal-content {
  max-height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: var(--space8);
}

.modal-button-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 56px;
  padding: var(--space8) var(--space24);
  margin-left: 0;
  background-color: var(--lightestNeutralColor);
  width: calc(100% - 48px);
}

.modal-button-bar > button {
  margin-right: var(--space16);
}

.modal-button > button:focus {
  outline: 2px solid var(--webkitHaloColor);
  outline-offset: 2px;
}

.hidden {
  display: none;
}

.modal-title {
  font-size: var(--font20);
}