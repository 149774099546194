.wrapper {
    max-width: 1280px;
    margin: 0 auto;
}

.breadcrumb {
    margin: var(--space16) var(--space40);
    color: #707064;
    cursor: default;
}

.spacer {
    margin: 0 var(--space8);
}

.breadcrumb a:link, .breadcrumb a:visited {
    color: #707064;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

@media all and (max-width: 720px) {
    .breadcrumb {
        margin: var(--space16) var(--space16);
        font-size: var(--font14);
    }
}
  