.course-info-section {
  margin-bottom: var(--space24);
}

.course-info-section:last-child {
  margin-bottom: 0;
}

.course-info-section > div {
  padding: var(--space8) var(--space16);
  border: solid 1px var(--lighterNeutralColor);
}

.course-info-wrap {
  min-width: 60%;
}
