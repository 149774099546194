.logo {
  display: inline-block;
  margin: 10px 0 0 20px;
}

.rtl .logo {
  margin: 10px 20px 0 0;
}

.logo > a > img {
  height: var(--space40);
}

.logo-text {
  display: inline-block;
  margin: -8px 0 0 2px;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  color: var(--darkPrimaryColor);
  letter-spacing: -3px;
  vertical-align: top;
}

@media all and (max-width: 545px) {
  .logo > a > img {
    height: var(--space32);
  }
}

@media all and (max-width: 475px) {
  .logo > a > img {
    height: var(--space20);
  }
  .logo {
    display: inline-block;
    margin: 5px 0 0 20px;
  }
}
