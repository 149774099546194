.table-root table {
  width: 100%;
  margin: var(--space8) -2px;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: var(--deepShadow);
}

.table-root thead th {
  text-align: left;
  white-space: nowrap;
  cursor: default;
  background-color: var(--lightestNeutralColor);
}

.rtl .table-root th {
  text-align: right;
}

.table-cell-back {
  margin: var(--space16);
}

th .table-cell-back {
  font-size: var(--font16);
  font-weight: bold;
  background-color: transparent;
  border: none;
}

.table-mobile .table-cell-back {
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.table-root th .table-cell-back {
  min-height: var(--space24);
}

.table-root th:first-child .table-cell-back {
  border-radius: 4px 0 0 4px;
}

.table-root th:last-child .table-cell-back {
  border-radius: 0 4px 4px 0;
}

.table-root th.sortable:active {
  color: inherit;
}

.table-root table a {
  color: var(--lightPrimaryColor);
  text-decoration: none;
}

.table-mobile .history-content > a,
.table-mobile .history-content > span {
  width: 100%;
}

.table-root th > .table-cell-back > span {
  margin: 0 var(--space8);
  font-size: var(--font14);
  vertical-align: middle;
}

.table-root tr {
  border-bottom: solid 1px var(--lighterNeutralColor);
}

.table-root tr:last-child {
  border-bottom: solid 0 var(--lighterNeutralColor);
}

.table-root td {
  padding: 1px;
}

.table-root td:not(:first-child) {
  font-size: var(--font14);
}

.table-root th.sortable .table-cell-back {
  cursor: pointer;
}

.table-root th.sortable .table-cell-back:hover {
  color: var(--darkPrimaryColor);
}

.table-root th.sortable .table-cell-back:active {
  cursor: pointer;
  outline: 0;
}

.table-no-results {
  padding: var(--space16);
  font-weight: bold;
}

.table-root a:hover {
  text-decoration: underline;
}

.table-hidden {
  display: none;
}

.table-mobile {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-top: solid var(--space8) var(--lightestNeutralColor);
}

.table-mobile .history-default-image,
.table-mobile .history-content-image {
  display: none;
}

.table-mobile .history-content-title {
  display: block;
  padding: 0;
  overflow: hidden;
  font-size: var(--font16);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-history-type {
  margin: var(--space16) 0 0;
  font-size: var(--font12);
  color: var(--lightNeutralColor);
}

.mobile-history-completed {
  font-size: var(--font12);
  color: var(--lightNeutralColor);
}

.mobile-history-certificate {
  margin: var(--space8) 0 var(--space16);
  font-size: var(--font14);
}

.mobile-history-certificate > div {
  display: inline-block;
  text-transform: uppercase;
}
