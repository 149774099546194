.root {
  max-width: 1280px;
  margin: 0 auto;
}

.root:focus {
  outline: 0;
}

.assignments {
  margin: 0 var(--space40);
}

.reviewCompleted {
  font-size: 14pt;
  font-weight: lighter;
  color: var(--lightNeutralColor);
}

.reviewCompletedLink {
  font-size: 14pt;
  font-weight: lighter;
  margin-left: 5px;
  color: var(--lightNeutralColor);
}

.completedDivWrapper {
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  box-shadow: var(--mediumShadow);
  border-radius: 5px;
}

.headerLeft > h2 {
  font-weight: normal;
  color: var(--darkNeutralColor);
}

.hide-for-announcer {
  opacity: 0;
  color: transparent;
  height: 0;
  width: 0;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
}

.card {
  margin: 0px 20px 20px 0px;
}

@media all and (max-width: 720px) {
  .root {
    margin: 0 var(--space16) 0 var(--space16);
  }

  .root h2 {
    font-size: var(--font14);
  }

  .assignments {
    margin: 0;
  }
  
}
