.skipLink {
  position: absolute;
  top: auto;
  left: -998px;
  width: 1px;
  height: 1px;
  color: var(--lightPrimaryColor);
  text-align: left;
  text-decoration: none;
}

.skipLink-focusable:focus {
  position: static;
  display: inline-block;
  width: auto;
  height: auto;
  margin: auto;
}
