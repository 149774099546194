@keyframes button-active {
  0% {
    color: var(--darkPrimaryColor);
    background-color: var(--reverseColor);
  }

  100% {
    color: var(--reverseColor);
    background-color: var(--lightPrimaryColor);
  }
}

@keyframes button-inactive {
  0% {
    color: var(--reverseColor);
    background-color: var(--lightPrimaryColor);
  }

  100% {
    color: var(--darkPrimaryColor);
    background-color: var(--reverseColor);
  }
}

.table-title,
.table-title-mobile {
  display: flex;
  margin: var(--space20) 0;
}

.table-title-mobile {
  flex-wrap: wrap;
  margin-bottom: var(--space24);
}

.table-title h2 {
  display: inline;
  font-weight: normal;
  color: var(--darkNeutralColor);
}

.table-title-mobile h2 {
  display: inline;
  font-weight: normal;
  font-size: var(--font18);
}

.table-title-left {
  flex: 2 1 66.66%;
}

.table-title-right {
  flex: 1 33.33%;
}

.rtl .table-title-right.right {
  text-align: left;
}

.history-download {
  display: inline-block;
  margin: 0 var(--space24);
  color: var(--lightPrimaryColor);
  text-decoration: none;
}

.history-download:hover {
  text-decoration: underline;
}

.history-download-icon {
  margin-right: var(--space8);
  font-size: var(--font16);
  color: var(--darkPrimaryColor);
  vertical-align: middle;
}

.history-download-text {
  font-size: var(--font14);
  vertical-align: top;
}

.table-title-icons {
  margin-top: -6px;
}

.table-title-icons button {
  padding: 3px;
  font-size: var(--font18);
  color: var(--darkPrimaryColor);
  vertical-align: middle;
  background-color: transparent;
  border: solid 0;
}

.table-title-mobile-show {
  margin-top: var(--space8);
}

.table-title-mobile-hide {
  display: none;
}

.table-title-mobile-search-open {
  animation: button-active 0.25s forwards;
}

.table-title-mobile-search-closed {
  animation: button-inactive 0.25s forwards;
}

.table-mobile-filter-item {
  display: flex;
  min-width: 100px;
}

.table-mobile-filter-item > span:first-child {
  flex: 1 1;
  margin-right: var(--space8);
}

.table-mobile-filter-item > span:last-child {
  flex: 5 1;
}
