.header {
  left: 0px;
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 100%;
  height: var(--space64);
  white-space: nowrap;
  text-align: center;
  box-shadow: var(--deepShadow);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0px auto;
}

.header h1 {
  margin: 0;
  display: inline-block;
}

.header > .headerContent > .right {
  display: flex;
  justify-content: center;
}

.rtl .header > .headerContent > .right {
  text-align: left;
}

.navHistory, .navAssignments {
  position: absolute;
  left: 0px;
  width: calc(100% - 40px);
  padding: 20px;
  text-decoration: none;
  color: var(--lightNeutralColor);
}

.disabledDropdown {
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  padding: var(--space18) var(--space12);
  cursor: not-allowed;
  color: var(--lighterNeutralColor);
}

.disabledDropdown > span:first-child {
    margin: 0px 8px 2px 0px;
    font-size: 1.11em;
}

.disabledDropdown > span:last-child {
  margin: 0px 20px 1px 0px;
}

@media all and (max-width: 770px) {
  .dropdown-header:last-child {
    margin-right: var(--space24);
  }
    
  .disabledDropdown > span:first-child {
    margin: 0px 9px 7px 0px;
    font-size: 1.14em;
  }

}
