:root {
  --interactions_backgroundComputedHoverColor: var(--darkPrimaryColor);
}

body {
  width: 100%;
  min-width: 330px;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: var(--lightestNeutralColor);
}

html {
  min-height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

#root > div {
  height: 100%;
}

.container {
  position: relative;
  height: 100%;
  padding-top: 64px;
  padding-bottom: var(--space40);
  margin: 0 auto;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}



a:focus,
button:focus {
  outline: var(--lightPrimaryColor) solid 2px;
  outline-offset: -3px;
}