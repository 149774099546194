@keyframes fade-in {
  0% {
    height: auto;
    opacity: 0;
  }

  100% {
    height: auto;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    height: auto;
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

.dropdown-contents {
  position: absolute;
  right: 0;
  z-index: var(--aboveAll);
  min-width: 100%;
  text-align: left;
}

.dropdown-menu {
  position: absolute;
  z-index: var(--aboveAll);
  text-align: left;
  visibility: hidden;
}

.dropdown-menu.expanded {
  min-width: 100%;
  visibility: visible;
  animation: fade-in 0.25s forwards;
}

.dropdown-menu.condensed {
  overflow: hidden;
  animation: fade-out 0.25s forwards;
}

.dropdown-menu a {
  display: block;
  height: 100%;
  padding: 0 var(--space24);
  color: var(--darkNeutralColor);
  text-decoration: none;
}

.dropdown-menu-item {
  display: block;
  height: 100%;
  padding: 0 var(--space24);
  color: var(--darkNeutralColor);
  text-decoration: none;
}

.dropdown-menu-item:hover {
  color: var(--reverseColor);
}

.dropdown-menu a::after {
  display: none;
  content: "\200E";
}

.langauge-rtl::after {
  content: "\202E";
}

.dropdown-menu a:hover {
  color: var(--reverseColor);
}

.dropdown-menu li {
  cursor: pointer;
}

.dropdown-contents li {
  cursor: pointer;
}

.dropdown-contents a:hover {
  color: var(--reverseColor);
}

.dropdown-contents li:hover {
  color: var(--reverseColor);
  background-color: var(--darkPrimaryColor);
}

.dropdown-menu a.dropdown-menu-focused {
  outline: var(--lightPrimaryColor) solid 2px;
  outline-offset: -1px;
}

.dropdown-menu ul {
  padding: var(--space16) 0;
  overflow-y: auto;
  white-space: nowrap;
  list-style-type: none;
}

.dropdown-contents ul {
  padding: var(--space16) 0;
  overflow-y: auto;
  white-space: nowrap;
  list-style-type: none;
}

.dropdown-header {
  position: relative;
  z-index: 1;
  display: inline-block;
  height: 100%;
}

.d-inline-block {
  display: inline-block;
}

.dropdown-header button,
.custom-dropdown
{
  position: relative;
  height: 30px;
  padding: 0px;
  color: var(--lightNeutralColor);
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: background-color 0.25s, color 0.25s;
  z-index: 100;
}

.custom-dropdown:hover button {
  color: var(--darkPrimaryColor);
}
.custom-dropdown button {
  padding: var(--space18) var(--space12);
} 

.custom-dropdown div[class^="NavMenu---menu"] {
  right: 0px;
  margin-top: 15px !important;
  left: auto !important;
}
#locale_selector-menu li:first-child,
#profile_selector-menu li:first-child {
  padding-top: var(--space2);
}

#locale_selector-menu li a:focus,
#profile_selector-menu li a:focus {
  color: #343434;
  color: var(--darkNeutralColor);
  outline: var(--lightPrimaryColor) solid 2px;
}


.dropdown-header button:focus,
.custom-dropdown button:focus {
  outline: var(--lightPrimaryColor) solid 2px;
  outline-offset: -3px;
}

.dropdown-header.active button {
  color: var(--reverseColor);
  background-color: var(--lightPrimaryColor);
}

.dropdown-header .dropdown-icon {
  vertical-align: middle;
}

.dropdown-header .dropdown-expand {
  vertical-align: middle;
}

.dropdown-header .dropdown-label,
.custom-dropdown .dropdown-label {
  display: inline-block;
  font-size: var(--font16);
}

.dropdown-header:hover .dropdown-label,
.custom-dropdown:hover .custom-dropdown-header {
  color: var(--lightPrimaryColor);
}

.dropdown-header:hover .dropdown-icon {
  color: var(--lightPrimaryColor);
}

.table-title-icons .dropdown-header.active:hover .dropdown-icon,
.table-title-icons .dropdown-header.active button {
  color: var(--reverseColor);
}

.table-title-icons .dropdown-menu a:hover {
  color: var(--lightPrimaryColor);
  text-decoration: none;
}

.dropdown-header:hover .dropdown-expand {
  color: var(--lightPrimaryColor);
}

.dropdown-label::after {
  content: "\202E‎";
}

.dropdown-header .dropdown-menu {
  margin-top: -16px;
  color: var(--darkNeutralColor);
}

.dropdown-header .dropdown-contents {
  margin-top: -16px;
  color: var(--darkNeutralColor);
}

@media all and (min-width: 811px) {
  .dropdown-header .dropdown-label {
    font-size: var(--font16);
  }

  .dropdown-header > button {
    font-size: var(--font16);
  }

  .dropdown-header .dropdown-icon,
  .custom-dropdown .dropdown-icon {
    font-size: var(--font18);
  }

  .dropdown-header .dropdown-expand {
    font-size: var(--font12);
  }

  .dropdown-header .rc-tooltip {
    display: none;
  }
}

@media all and (min-width: 771px) and (max-width: 810px) {
  .dropdown-header .dropdown-label {
    font-size: var(--font14);
  }

  .dropdown-header > button {
    font-size: var(--font14);
  }

  .dropdown-header .dropdown-icon,
  .custom-dropdown .dropdown-icon {
    font-size: var(--font16);
  }

  .dropdown-header .dropdown-expand {
    font-size: var(--font10);
  }

  .dropdown-header .rc-tooltip {
    display: none;
  }
}

@media all and (max-width: 770px) {
  .dropdown-menu {
    right: 0;
  }

  .dropdown-header .dropdown-label,
  .custom-dropdown .dropdown-label {
    display: none;
    font-size: var(--font12);
  }

  .dropdown-header > button {
    font-size: var(--font12);
  }

  .dropdown-header .dropdown-icon,
  .custom-dropdown .dropdown-icon {
    font-size: var(--font18);
  }

  .dropdown-header .dropdown-expand,
 .custom-dropdown span[class^="Menu---iconDropdown"]{
    display: none;
    font-size: var(--font10);
  }  
}

.rtl .dropdown-label {
    padding-left: 8px;
    padding-right: 8px;
}

.rtl .custom-dropdown a[class^="Header_navAssignments"] {
  left: unset;
  width: unset;
  padding: unset;
}

.rtl .custom-dropdown a[class^="Header_navHistory"] {
  left: unset;
  width: unset;
  padding: unset;
}