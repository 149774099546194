.footer-root {
  margin: var(--space48) 0;
  text-align: center;
}

.footer-root > div {
  margin: var(--space16) 0;
}

.footer-links {
  font-weight: bold;
}

.footer-links > a {
  margin: 0 var(--space16);
  color: var(--lightPrimaryColor);
  text-decoration: none;
}

.footer-links > a:hover {
  text-decoration: underline;
}

.footer-copyright {
  font-size: var(--font12);
  color: var(--darkNeutralColor);
}

.footer-trademarks {
  font-size: var(--font10);
  font-style: italic;
  color: var(--darkNeutralColor);
}
