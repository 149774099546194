.wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.history {
  margin: 0 var(--space40);
}

.historyContent {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.historyContent a {
  display: table-row;
  cursor: pointer;
}

.contentImage,
.defaultImage {
  display: table-cell;
  width: 115px;
  height: 65px;
  vertical-align: middle;
  background: var(--grayGradient);
}

@media screen and (-ms-high-contrast: active) {
  .contentImage,
  .defaultImage {
    display: none !important;
  }
}

.contentImage > div {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.defaultImage > div {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.contentTitle {
  display: table-cell;
  padding-left: var(--space16);
  font-weight: bold;
  vertical-align: middle;
  text-align: left;
}

.contentLink .contentTitle {
  cursor: pointer;
  color: var(--lightPrimaryColor);
}

.rtl .contentTitle {
  padding-right: var(--space16);
  padding-left: initial;
}

:global(.rtl) .contentTitle {
  padding-right: var(--space16);
  padding-left: initial;
}

.action {
  text-align: center;
  white-space: nowrap;
}

.action a {
  cursor: pointer;
}

.action > div {
  display: inline-block;
  margin: 0 var(--space8);
}

.noDate {
  display: inline-block;
  width: 85px;
  text-align: center;
}

.popupIcon {
  margin: -3px 0 0 4px;
  vertical-align: middle;
}

:global(.rtl) .popupIcon {
  padding-right: 5px;
}

@media all and (max-width: 720px) {
  .wrapper {
    margin: var(--space40) var(--space16) var(--space40) var(--space16);
  }
}
